.wpBanner {
    .breadcrumbs {
        padding: 5px 0;
    }
    .productSearchName {
        padding: 15px 0;
    }
    .tableBanner {
        .bannerImage {
            width: 200px;
            height: 100px;
        }
    }
}